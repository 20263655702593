.card-light {
  width: 100%;
}

.heading-2 {
  font-weight: 600;
  color: #c6213f;

}

.cta-curvy {
  display: block;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 8px;
  padding: 8px 30px;
}

.action-wrapper {
  display: flex;
  align-items: center;
}

.action-wrapper a.refer {
  font-size: 15px;
  text-decoration: underline !important;
  margin-left: 25px;
  margin-bottom: 17px;
  color: rgba(49, 49, 49, 0.8);
}

.otp-wrap {
  align-items: normal !important;
  top: 30px;
  height: fit-content;
}

.cta-otp {
  border-radius: 8px;
  max-height: 40px;
}

.bottom-text a {
  color: #c6213f;
}

span.deleteField {
  float: right;
  position: absolute;
  right: 20px;
  font-size: 9px;
  color: brown;
  top: 35px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .otp-wrap {
    top: 0;
  }
}