/* GENERAL  */

html {
  scroll-behavior: smooth;
}


body {
  min-height: 100%;

}

a {
  text-decoration: none !important;

}



[data-target="#mainMenu"] {
  position: relative;
  z-index: 999;
}


#mainMenu li>a {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.main-header.fixed-nav #mainMenu li>a {
  color: #fff;
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin-right: 20px;
}

#mainMenu li>a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: #fff;
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

#mainMenu li>a:hover::before,
#mainMenu li>a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li>a::before {
  background: #000;
}

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  transition: all 0.4s ease;
  background: #c6213f;
  border-bottom: 3px solid #53101d;
}

.main-header.fixed-nav {
  top: 0;
  background: #fff;
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand>img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand>img:first-of-type {
  display: none;
}

.navbar-brand {
  color: #fff;
}

.main-header .navbar-brand img {
  max-width: 100%;
  height: auto;
}

/* main-header end */
@media (max-width: 991px) {
  /*header starts*/

  .collapse.in {
    display: block !important;
    padding: 0;
    clear: both;
  }

  #mainMenu li:not(:last-of-type) {
    padding-top: 5px;
    margin-right: 0px !important;
    padding-bottom: 5px;
  }

  .top {
    margin-top: -10px !important;
    margin-bottom: 10px !important;
    float: right !important;

  }

  .mt-44,
  .my-44 {

    top: 45px !important;
    position: relative !important;
    margin-bottom: 70px !important;
  }



}

.tp {
  text-align: left;
  padding-left: 15px;
  margin: 0px;
}

.tp-1 {
  float: right;
  margin-right: 15px;
}



.navbar-toggler {
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 0;
  border: none;
  border-radius: 0;
  outline: none !important;
}

.main-header .navbar {
  float: none;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.main-header .navbar-nav {
  padding: 25px 0px 25px 0px;
}

.main-header .navbar-nav li .nav-link {
  text-align: center;
  padding: 20px 15px;
  border-radius: 0px;
}

/**/
.main-header .navbar-toggler .icon-bar {
  background-color: #fff;
  margin: 0 auto 6px;
  border-radius: 0;
  width: 30px;
  height: 3px;
  position: absolute;
  right: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.main-header .navbar .navbar-toggler .icon-bar:first-child {
  margin-top: 3px;
}

.main-header .navbar-toggler .icon-bar-1 {
  width: 10px;
  top: 0px;
}

.main-header .navbar-toggler .icon-bar-2 {
  width: 16px;
  top: 12px;
}

.main-header .navbar-toggler .icon-bar-3 {
  width: 20px;
  top: 21px;
}

.main-header .current .icon-bar {
  margin-bottom: 5px;
  border-radius: 0;
  display: block;
}

.main-header .current .icon-bar-1 {
  width: 18px;
}

.main-header .current .icon-bar-2 {
  width: 30px;
}

.main-header .current .icon-bar-3 {
  width: 10px;
}

.main-header .navbar-toggler:hover .icon-bar {
  background-color: #fff;
}

.main-header .navbar-toggler:focus .icon-bar {
  background-color: #fff;
}

.logo-2 {
  font-weight: 700;
  font-size: 20px;
}



.modal {
  overflow-y: auto;
}

.custom-inner-container {
  margin: 2rem 1.5rem 1.8rem 1.5rem;
}

.custom-modal-container {
  padding: 1.5rem 3rem;
}

.navbar a {
  margin-left: 5px;
}

.text-white-1 {
  color: #fff !important;
  text-align: right;
}

.text-white-2 {
  color: #fff !important;
  text-align: left;
}

.navbar a:hover {
  color: rgb(219, 219, 219);
}

.navbar-brand {
  font-size: 0.9rem;
}

.centered-ul li {
  margin: 0 1rem;
}

.custom-button {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  border: 2px solid #fff;
  padding: 10px 35px 10px !important;
  font-size: 13px;
  color: white !important;
  border-radius: 9px;
  background: transparent linear-gradient(73deg, #c6213f 0%, #bb1019 100%) 0% 0% no-repeat padding-box;
  opacity: 0.9;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: revert;
  margin: 9px 0px 0px;
  cursor: pointer !important;
}

.custom-button:hover {
  /* background: none; */
  /* border: 1px solid #C6213F; */
  /* background-color: transparent; */
  color: #fff;
}

.custom-button-small {
  padding: 0.3rem 1rem !important;
  font-size: 0.7rem;
}

h1 {
  color: #c6213f;
  font-size: 1.5rem;
}

.static {
  position: static !important;
}

/* text {
    margin-right: 1rem;
    color: rgb(97, 96, 96);
    font-size: 0.7rem;
    cursor: pointer;
} */

/* table thead {
    border-bottom: solid 1px #c6213fad;
}

table thead th {
    color: #C6213F;
    font-size: 0.8rem;
    text-align: center;
}

table tbody tr {
    text-align: center;
    font-size: 0.9rem;
    cursor: pointer;
} */

/* .modal-title,
.cross {
    color: #C6213F;
    font-size: 1.5rem;
}

.custom-modal-width {
    max-width: 50%;
} */

.modal .info {
  font-size: 0.9rem;
  line-height: 1.8rem;
  text-align: left;
}

.modal .copyCode {
  line-height: 1rem;
  color: #c6213f;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}

.modal i {
  color: #c6213f;
}

/* .fullWidthTextArea {
    width: 100%;
    border: 2px solid rgb(49,49,49,0.6) !important;
    border-radius: 10px;
} */

.dropdown-menu {
  box-shadow: 0px 5px 10px #000000;
  border: none;
  text-align: left;
}

.dropdown-item {
  color: #c6213f;
}

.dropdown-item:hover {
  background-color: #c6213fd8;
  color: #ffffff;
}

.dropdown-item:focus {
  background-color: #c6213f;
  color: #ffffff;
}

.custom-border {
  border-bottom: solid 1px #c6213fad;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffe1e6e5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  border-radius: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #c6213f;
}

input:focus+.slider {
  box-shadow: 0 0 1px #c6213f;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* GENERAL - END  */

/* CUSTOM  */

#login .card h4 {
  font-weight: bold;
  color: #c6213f;
}

#login p {
  color: #555555;
  font-weight: bold;
}

#login p a {
  font-weight: bold;
  color: #c6213f;
}

.textBackdrop {
  color: #c6213f;
  background-color: #c6213f12;
  padding: 0.8rem 1rem;
  border-radius: 6px;
  margin-top: 22px;
  font-weight: 600;
  border: 1px dashed #c6213f;
}


.D-heading {
  font-weight: 600;
  color: #000;
}

#sampleDoc img,
#statusFinal img {
  /* width: 45%; */
  height: 60vh;
  max-height: 250px;
}

.custom-pagination {
  font-size: 0.8rem;
  color: #c6213f;
}

.custom-pagination i {
  border: solid 2px #c6213f;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
  margin: 0 0.5rem;
}

.custom-pagination i:hover {
  background-color: #c6213f;
  color: white;
  cursor: pointer;
}

#uploadDoc .type {
  cursor: pointer;
}

#uploadDoc .type p {
  margin: 2rem 0 !important;
  font-size: 0.8rem;
}

.select {
  padding: 0rem !important;
  color: white;
  height: 8vh;
  line-height: 8vh;
}

#uploadDoc .type.regular {
  border: solid #2dc84c 6px;
  border-radius: 20px;
  color: #2dc84c;
}

#uploadDoc .regular .regular-inner {
  padding: 2rem 2rem 0 2rem;
}

#uploadDoc .regular .select {
  background-color: #2dc84c;
}

#uploadDoc .type.delayed {
  float: left;
  border: solid darkred 6px;
  border-radius: 20px;
  color: darkred;
}

#uploadDoc .delayed .delayed-inner {
  padding: 2rem 2rem 0 2rem;
}

#uploadDoc .delayed .select {
  background-color: darkred;
}

#uploadDoc .type.urgent {
  float: right;
  border: solid #f68c00 6px;
  border-radius: 20px;
  color: #f68c00;
}

#uploadDoc .urgent .urgent-inner {
  padding: 2rem 2rem 0 2rem;
}

#uploadDoc .urgent .select {
  background-color: #f68c00;
}

#uploadDocRegular ol li {
  line-height: 1.15;
  font-size: 0.9rem;
}

/* COMMONS */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&amp;display=swap");

body {
  height: 100% !important;
  font-size: 14px;
}

.fullWidthTextArea {
  width: 100%;
  border: 2px solid rgb(49, 49, 49, 0.6) !important;
  border-radius: 10px;
  padding: 10px;
}

.display-n {
  display: none;
}

.modal-title {
  color: #c6213f;
  font-size: 1.5rem;
  width: 100%;
}

.cross {
  color: #f68b9e;
  font-size: 1.4rem;
  cursor: pointer;
  float: right;
}

.cross:hover {
  color: #c6213f;
  font-size: 1.4rem;
  cursor: pointer;
}

.custom-modal-width {
  max-width: 50%;
}

.cta {
  width: fit-content;
  padding: 10px 25px;
  font-size: 15px;
  border: 1px solid #c6213f;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 600;
  color: #c6213f;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;
}

.cta:hover {
  text-decoration: none;
  color: #fff;
}

.cta-fill {
  background-color: #c6213f;
  color: #fff;
}

label {
  font-size: 15px;
  font-weight: 600;
  color: rgb(49, 49, 49, 0.7);
}

input,
select {
  border: 2px solid rgb(49, 49, 49, 0.6) !important;
  border-radius: 8px !important;
}

.form-control:focus {
  border-color: rgba(49, 49, 49) !important;
  box-shadow: none !important;
}

select:focus {
  border-color: rgba(49, 49, 49) !important;
  box-shadow: none !important;
}

.card-light {
  border-radius: 12px;
  padding: 5px 35px;
  border: none;

}


.custom-container {
  padding-right: 9rem;
  padding-left: 9rem;
}

footer {
  color: #fff;
}

ul {
  padding: 0px;
}

ol,
ul {
  margin-bottom: 15px;
  line-height: 30px;
}

.red {
  color: #c6213f;
  font-size: 25px;
}

.social-icon {
  padding: 0;
  margin-bottom: 0px;
  float: right;
}

.green {
  color: green;
  font-weight: 600;
  font-size: 15px;
}

.redd {
  color: rgb(194, 1, 1);
  font-weight: 600;
  font-size: 15px;
}


.social-icon li {
  list-style: none;
  display: inline-block;
}

.social-icon li i {
  font-size: 14px;
  color: #262725;
  border: solid 2px #ffffff;
  height: 31px;
  width: 31px;
  text-align: center;
  vertical-align: middle;
  border-radius: 100px;
  line-height: 27px;
  margin-right: 15px;
  transition: 1s;
  background: #fff;
}

.social-icon li i:hover {
  border: solid 2px #262725;
  color: #ffffff;
  background: #262725;
}

.phone-no i {
  position: relative;
  margin-right: 14px;
  font-size: 43px;
  top: 5px;
}

.phone-no {
  margin-top: -22px;
  text-align: right;
}

.footer-day-time {
  padding-bottom: 30px;
  border-bottom: 2px solid #7a6f6f;
  padding-top: 14px;
  margin-bottom: 55px;
}

.footer-day-time ul li {
  display: inline;
  margin-right: 20px;
}

.footer-day-time ul li:last-child {
  margin-right: 0px;
}

.phone-no a {
  color: #fff;
  font-family: PlayfairDisplay-Black;
  font-size: 34px;
  font-weight: bold;
}

.footer-top {
  background: #590f1d;
  padding: 50px 0 50px;
  border-top: 4px solid #53101d;
}

.footer-top h4 {
  font-size: 25px;
  margin-top: 4px;
  font-weight: 700;
  margin-bottom: 19px;

}

.footer-top p {
  font-size: 14px;
  line-height: 2;
}

footer p {
  margin-bottom: 0;
}

.footer-logo {
  display: block;
  margin-bottom: 32px;
}

.address1 li {
  list-style: none;
  position: relative;
  padding: 0px 0 14px 34px;
  line-height: 26px;
}

ul.address1 span {
  position: absolute;
  width: 40px;
  max-width: 40px;
  left: 0;
}

.address1 li a {
  color: #fff;
  text-decoration: none;
}

ul.address1 i {
  width: 20px;
  position: absolute;
  left: 0px;
  text-align: center;
  font-size: 28px;
  top: 0;
}

ul.address1 i.fa-envelope {
  font-size: 18px;
  top: 4px;
}

footer ul.social-icon {
  float: left;
}

footer .social-icon li i:hover {
  background: #4b8800;
  border-color: #4b8800;
}

.footer-bottom {
  background-image: linear-gradient(to left, #051937, #221336, #36092c, #42001c, #460101);
  padding: 10px 0px;
}

.footer-bottom ul li {
  display: inline;
  margin-right: 20px;
  font-size: 18px;
}

.footer-bottom ul li a {
  color: #fff;
}

.footer-bottom ul {
  float: right;
}

.footer-bottom ul li:last-child {
  margin-right: 0;
}

.copyright {
  font-size: 18px;
}

p.error {
  color: firebrick !important;
  font-size: 0.8rem !important;
}

p.success {
  color: darkgreen;
  font-size: 0.8rem;
}

.card-light {
  padding: 0px 0px 20px 0px !important;
  margin-top: 40px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}


.custom-inner-container {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}


.cta-hollow {
  margin-top: 120px;

}

.disable-btn {
  pointer-events: none;
  background-color: grey;
  border: none;
  background: grey;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.zip_image {
  height: 110px;
}

.is_final_draft {
  font-size: 20px;
}

.toast {
  opacity: 0.9 !important;
}

.paymentDUE {
  color: #ff0000;
}

.paymentPARTIALLY_PAID {
  color: #f68c00;
}

.paymentPAID {
  color: #2dc84c;
}


.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

img.icon {
  height: 14px;
  width: 14px;
}

.card {
  position: static;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;

}

.he-1 {
  text-align: center;
  color: #7d0f0f;
  font-size: 30px;
  font-weight: 600;
  margin-top: 5px;
  font-family: initial;
}

.heading-1 {
  font-size: 16px;
  font-weight: 600;
  color: #7d0f0f;
}

.right {
  margin-left: 66%;
}

.hr {
  border-top: 1px solid #00000024;
}



.heading {
  color: #c6213f;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 23px;
  font-family: "Gill Sans", sans-serif;
}

.dropdown-toggle {
  position: absolute;
  z-index: 999;
}

.card-1 {
  border-radius: 20px;
  margin-top: 20px;

}

.card-body {
  flex: 1 1 auto;
  min-height: 5px;
}

.mt-0 {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
  padding: 0px 0px 0px 45px;
}

.admin-1 {
  color: #b01a35;
  font-weight: 700;
  font-size: 30px;
}

#HeaderDropdown {
  margin-bottom: 15px !important;
  margin-right: 15px !important;
}

.mt-4,
.my-4 {
  margin-top: 120px !important;
  margin-bottom: 40px !important;
}

.fo-p {
  margin-top: 40px;
}















.bg {
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 2rem !important;
  font-size: 0.9rem;
  color: white;
  border-radius: 10px;
  background: transparent linear-gradient(73deg, #c6213f 0%, #bb1019 100%) 0% 0% no-repeat padding-box;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #0b2b4a !important;
  border-color: #c6213f !important;
}


.btn-secondary:hover {
  color: #fff;
  background-color: #0b2b4a !important;
  border-color: #c6213f !important;
}


/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 900px) {
  .custom-container {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .tp {
    display: none;
  }

  #HeaderDropdown {
    padding-top: 0px !important;
    float: right;

  }

  .mt-5,
  .my-5 {
    margin-top: 1rem !important;
  }

  .D-heading {
    font-weight: 600;
    color: #000;
    text-align: center;
    font-size: 17px;
  }

  #delayed {
    padding: 20px 30px 50px 30px;
    border-bottom: 5px solid #460103;
    margin: 20px 0px 20px;
    background: #e2e2e2;
    border-radius: 15px;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    height: 380px;
    width: 100%;
  }


  .d-1 {
    padding: 20px 30px 50px 30px;
    border-bottom: 5px solid #460103;
    margin: 20px 0px 20px;
    background: #e2e2e2;
    border-radius: 15px;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    height: 800px;
    width: 100%;
  }

  .h11 {
    font-size: 16px !important;
    text-align: center !important;
    background-image: linear-gradient(to left, #f2eeee, #d7c5c9, #44021f, #400f2214, #f2eeee) !important;
    padding: 15px !important;
    color: #fff !important;
    font-weight: 700 !important;
    text-shadow: #000 1px 0 10px !important;
    margin-top: 40px !important;
    margin-bottom: 0px !important;
  }

  #HeaderDropdown {
    padding-top: 16px;
    float: right;
  }

  .right {
    margin-left: 22%;
    margin-top: 8%;
  }

  .text-white-2 {
    color: #fff !important;
    text-align: center;
  }

  .text-white-1 {
    color: #fff !important;
    text-align: center;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 6.75rem auto;
  }




}


@media (max-width: 576px) {

  .sample-doc {
    padding: 10px;
    text-align: center;
  }


  .mt-44,
  .my-44 {

    top: 36px !important;
    position: relative !important;
    margin-bottom: 70px !important;
  }







  .btn-secondary {
    align-items: center !important;
    background-color: #FCFCFD !important;
    color: #c6213f !important;
    display: inline-flex !important;
    font-family: revert !important;
    justify-content: center !important;
    list-style: none !important;
    overflow: hidden !important;
    position: relative !important;
    text-align: left !important;
    transition: box-shadow .15s, transform .15s !important;
    -webkit-user-select: none !important;
    touch-action: manipulation !important;
    white-space: nowrap !important;
    will-change: box-shadow, transform;
    border: 2px solid #8d8083 !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    border-radius: 70px !important;
    padding: 10px 10px 8px 10px !important;
  }

  .img-re {
    margin-bottom: 20px;
    display: none;
  }

  .footer-top h4 {
    font-size: 25px;
    margin-top: 22px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .btn-secondary.focus,
  .btn-secondary:focus {
    color: #000 !important;
    border-color: #200000 !important;
    box-shadow: 0 0 0 .2rem rgba(255, 255, 255, 0.5) !important;
  }






  ol,
  ul {
    margin-bottom: 15px;
    margin-left: 0px;
    line-height: 30px;
  }


  .card-light {
    width: 100%;
  }

  .card-light {
    padding: 5px 15px;
  }

  .card {
    position: static;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;

  }


}










.m4 {
  color: #c6213f;
  font-weight: 600;
  text-align: center;
  margin-top: 0px !important;

}


#HeaderDropdown a {
  color: #bb1019;
}

#HeaderDropdown a:hover {
  color: #ffffff;
}

.abc {
  background: #ececec;
  padding: 6px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.top {
  margin-top: 25px;
  float: right;
  margin-right: 15px;
}

.left {
  font-weight: 600;
  margin-top: 0px;
}

.text-script {
  align-items: center !important;
  padding-top: 100px;
}

.db {
  font-size: 19px;
}


.mt-15 {
  margin-bottom: 20px;
}

.btn-secondary {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 42px;
  box-sizing: border-box;
  color: #c6213f;
  display: inline-flex;
  font-family: revert;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  border: 2px solid #37082a6b;
  font-weight: 600;
  font-size: 15px;
  padding: 10px 20px 10px 20px;


}

.why-us {
  background: #4f50020d;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 40px;
  margin-bottom: 40px;


}


.responsive {
  width: 100%;
  height: auto;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

#HeaderDropdown {
  padding-top: 28px;
  float: right;

}

.top-header {
  background-image: linear-gradient(to left, #051937, #221336, #36092c, #42001c, #460101);
  text-align: center;
  padding: 5px 0px 5px 0px;
  color: #fff;
  font-weight: 600;
  border-bottom: 1px dotted;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;

}



.h11 {

  font-size: 23px;
  text-align: center;
  background-image: linear-gradient(to left, #f2eeee, #d7c5c9, #44021f, #400f2214, #f2eeee);
  padding: 10px;
  color: #fff;
  font-weight: 700;
  text-shadow: #000 1px 0 10px;
  margin-top: 39px;
}

.get-quate {
  padding: 10px 0px 10px 0px;
}

.avform {
  padding: 50px;
  background: #f4f1f1;
}

.mt-9 {
  padding-top: 40px;
}

.mt00 {
  padding: 0px 15px 0px 15px;
}



.country {
  background: black;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid rgb(112, 111, 111) !important;
  border-radius: 60px !important;
  text-align: center;

}

.bbb {
  float: left;
}

.btn-red {
  background: #c6213f;
  padding: 10px 20px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  border: 0px solid #f2f2f2;
  border-radius: 7px;
  margin-left: 21px;
}