label {
  font-size: 15px;
  font-weight: 600;
  color: rgb(49, 49, 49, 0.7);
}

input {
  border: 1px solid rgb(49, 49, 49, 0.6) !important;
  border-radius: 8px !important;
}

.form-control:focus {
  border-color: rgba(49, 49, 49) !important;
  box-shadow: none !important;
}

.form-group .forgot-pass {
  font-size: 12px;
  float: right;
  font-weight: 600;
  margin-top: 8px;
  color: rgba(49, 49, 49, 0.5);
}

.custom-control-label {
  font-size: 14px;
  color: #c6213f;
}

.custom-control-label:before {
  background-color: #fff;
  border-color: rgba(49, 49, 49, 0.5);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #c6213f;
  border-color: transparent;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.cta-curvy {
  display: block;
  margin-top: 10px;
  margin-bottom: 25px;
  border-radius: 8px;
  padding: 12px 35px;
}

.new-user {
  margin-top: 20px;
  font-size: 15px;
}

#forgotPass .form-group {
  height: 100px;
}

.otp-wrap {
  align-items: normal !important;
  top: 30px;
  height: fit-content;
}

.cta-otp {
  border-radius: 8px;
}

#forgotPass .custom-modal-width {
  max-width: 35%;
}

#forgotPass p.error {
  line-height: 1.2;
}

/* Admin Login */
.admin-login {
  min-height: 100vh;
}

.admin-login #login-bg {
  background: transparent;
}

.admin-login footer {
  position: absolute;
  width: 100vw;
  bottom: 0;
}