.dashboard-container {
    text-align: justify;
    width: 100%;

}

.card-light {
    padding: 5px 15px;
}

table thead {
    border-bottom: solid 1px #c6213fad;
}

table thead th,
table tbody th a {
    font-size: 0.8rem;
    text-align: center;
}

table tbody tr {
    text-align: center;

}

p.text {
    margin-right: 1rem;
    color: rgb(97, 96, 96);
    font-size: 0.9rem;
}

p.text-small {
    margin-top: 1rem;
    color: rgb(97, 96, 96);
    font-size: 0.7rem;
}

text {
    margin-right: 1rem;
    color: rgb(198, 33, 63);
    font-size: 14px;
    cursor: pointer;
    background: white;
    padding: 10px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    width: 195px !important;
    display: block ruby;
    text-align: center;
    margin-bottom: 10px;
}

.docFIRST_DRAFT,
.docFINAL,
.docDELIVERED,
.payments {
    cursor: pointer;
}

input[type=file]::file-selector-button {
    background: transparent linear-gradient(71deg, #C6213F 0%, #BB1019 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 10px 15px;
    margin-right: 20px;
    font-size: 14px;
    font-weight: 600;
    border: 2px solid;
}

#submitDoc .d-flex {
    align-items: center;
}

.file-control {
    border: none !important;
    padding-left: 0;
    height: 60px;
    font-size: 13px;
    width: fit-content;
}

span.remove-file {
    font-weight: 600;
    color: #C6213F;
    transform: translateY(11px);
    cursor: pointer;
}

#submitDoc input,
label,
select,
option,
textarea,
.custom-select {
    font-size: 0.9rem;
}

#submitDoc .custom-modal-width {
    max-width: 55%;
}

#submitDoc ol {
    font-size: 0.9rem;
}

#submitDoc .file-group p.error {
    margin-left: 10px;
    margin-top: 6px;
}

/* For forced responsive */
@media(max-width: 800px) {
    body {
        width: 100%;
    }
}

h1.filter-dropdown {
    font-weight: 600;
}

#uploadDoc .priroty-wrap {
    justify-content: center;
}

.table tbody tr {
    position: relative;
}

.table tbody th.notify::before {
    content: "";
    height: 20px;
    width: 6px;
    background-color: #C6213F;
    position: absolute;
    left: 0;
    /* top: 20px; */
    border-radius: 5px;
}

p.alert {
    color: darkred;
    font-weight: 600;
}

.table-bg {
    background: #000;
    color: #FFF;
    text-transform: uppercase;
    border-bottom: 3px solid #c6213f;
}

.table td,
.table th {
    padding: 15px 05px 15px 05px !important;
    vertical-align: middle !important;
    border-top: 1px solid #b1aeae !important;
}

.admin-dashboard .truncate {
    width: 222px;

}

#admin-dashboard table tbody tr {
    font-size: 0.8rem;
}

.admin-dashboard .custom-button-small {
    padding: 0.4rem 1rem !important;
}

.admin-dashboard table tbody tr td.editable div {
    border: 2px solid #C6213F;
    padding: 5px 4px;
    margin: -5px 0px;
    display: inline-block;
    border-radius: 5px;
}

.admin-dashboard table tbody tr td.editable div input {
    width: 35px;
    text-align: center;
    border: none !important;
    margin: 1px;
}

.admin-dashboard table tbody tr td.editable div input:focus-visible {
    border: none !important;
}

.flex-btn-wrap {
    position: absolute;
    bottom: 0;
    right: 12px;
}

.admin-dashboard .flex-btn-wrap .custom-button {
    padding: 0.5rem 1.7rem !important;
}

#sampleDoc .d-flex {
    align-items: center;
}

#sampleDoc span.remove-file,
#submitDoc span.remove-file {
    transform: translateY(-3px) !important;
}

#sampleDoc p.error {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

#uploadDraftFile p.error,
#uploadFile p.error,
#draftFeedback p.error,
#finalDocUpload p.error {
    margin-top: 0.8rem;
    margin-left: 0.5rem;
}

#updateDoc .modal-content p {
    font-weight: 600;
    text-align: left;
    font-size: 0.9rem;
    color: #6e6e6e;
}

#updateDoc .modal-content p span {
    color: #C6213F;
}

.icon {
    cursor: pointer;
}

td.draft-upload,
td.draft-feedback,
td.final {
    cursor: pointer;
}

td.status {
    font-weight: 600;
}

a.no-doc img {
    filter: grayscale(1);
}

.admin-dashboard #referralCode .modal-content ul.nav-tabs {
    margin: 10px 0 15px;
    padding-bottom: 4px;
}

.admin-dashboard #referralCode .modal-content ul.nav-tabs li a {
    padding: 7px 15px;
    border-radius: 5px;
    display: block;
    color: #c44234;
}

.admin-dashboard #referralCode .modal-content ul.nav-tabs li a.active {
    background: #fae1e6;
}

#referralCode .modal-content .tab-pane .textBackdrop .pull-right {
    font-weight: 600;
}

#referralCode input.disc-coupon {
    border: none !important;
    width: 100%;
}